import React, {Component} from 'react';
import Layout from './hoc/Layout/Layout'
import BurgerBuilder from "./containers/BurgerBuilder/BurgerBuilder";
import Checkout from './containers/Checkout/Checkout'
import { Route, Switch } from 'react-router-dom'
import Orders from "./containers/Orders/Orders";
import SignInSide from "./containers/Auth/Auth";


class App extends Component {

    render() {
        return (
                <div>
                    <Switch>
                        <Route path={"/"} exact component={SignInSide}/>
                        <Layout>
                            {/* Switch will only load one, the first match*/}
                            <Route path={"/checkout"} component={Checkout}/>
                            <Route path={"/orders"} component={Orders}/>
                            <Route path={"/burger"} component={BurgerBuilder}/>
                        </Layout>
                    </Switch>
                </div>
        );
    }
}

export default App;
