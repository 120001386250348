import React, {Component} from 'react';
import classes from './Model.module.css'
import Aux from '../../../hoc/Aux/Aux'
import Backdrop from '../Backdrop/Backdrop'


class Model extends Component {


    /**
     * Update if the 'show' state changed
     * prevProps.show !== this.props.show
     *
     * This is tricky. Here is a function that will return TRUE to force
     * a display update. We only want to return true when we really NEED to
     * update the display. With the <OrderSummary/> case we only want to
     * draw the <OrderSummary/> if we have turned on the props.show flag.
     *
     *
     * Update if the 'children' of the component changed.
     * prevProps.children !== this.props.children
     *
     * When we render <Modal/> it is wrapping either an <OrderSummary/> or
     * a <Spinner/>. We set the props.show flag when we pass in an <OrderSummary/>
     * to trigger returning TRUE. However, for the <Spinner/> we aren't passing
     * in a flag with this checking for 'children' we would never return TRUE and
     * therefore the <Spinner/> wouldn't show up. 'children' will contain the property
     * this.props.children.type = function, in the spinners case function = 'spinner'.
     *
     * @param prevProps
     * @param prevState
     * @returns {boolean}
     */
    shouldComponentUpdate(prevProps, prevState) {
        if (prevProps.show !== this.props.show || prevProps.children !== this.props.children) {
            console.log('[Model.js] shouldComponentUpdate');
            return true;
        }
        return false;
    }

    render() {
        return (
            <Aux>
                <Backdrop show={this.props.show} clicked={this.props.modalClosed}/>
                <div
                    className={classes.Modal}
                    /*
                        This inline styling is just to remind you that it can be done here.
                        Styling could also just be put in Model.module.css

                        translateY(0): this is the position defined in CSS. The Zero says, do nothing.
                        translateY(-100vh) where vh = viewport height. This will slide it off the screen.
                        The negative 100 means: outside the viewable area.

                        opacity controls whether you can actually see the Component.
                     */
                    style={{
                        transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
                        opacity: this.props.show ? '1' : 0
                    }}
                >
                    {/* This renders all children Components; in this case <OrderSummary/>*/}
                    {this.props.children}
                </div>
            </Aux>
        )
    }
}


export default Model