import React, {Component} from 'react';
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import * as actions from "../../store/actions";
import connect from "react-redux/lib/connect/connect";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Snackbar from '@material-ui/core/Snackbar';
import {Alert} from "@material-ui/lab";

const useStyles = (theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    }
});

class SignIn extends Component {

    state = {
        formData: {
            email: '',
            password: '',
        },
        open: true
    }

    componentDidMount() {
        console.log("componentDidMount")
    }

    handleChange = (event) => {
        const {formData} = this.state;
        formData[event.target.name] = event.target.value;
        this.setState({formData});
    }

    handleSubmit = (bullshit) => {
        bullshit.preventDefault();
        this.setState({open: true});
        this.props.onAuth(this.state.formData.email, this.state.formData.password);
    }

    render() {
        console.log("Render!");
        const {classes} = this.props;
        const {formData} = this.state;

        let errorMessage = null;
        if (this.props.error) {
            errorMessage = (
                <Snackbar open={this.state.open}
                          autoHideDuration={6000}
                          onClose={() => this.setState({open: false})}
                >
                    <Alert onClose={() => this.setState({open: false})}
                           severity="error">
                        {this.props.error.message}
                    </Alert>
                </Snackbar>
            )
        }

        return (
            <React.Fragment>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <ValidatorForm className={classes.form} onSubmit={this.handleSubmit}>
                    <TextValidator
                        key={'email'}
                        onChange={this.handleChange}
                        validators={['required', 'isEmail']}
                        errorMessages={['this field is required', 'email is not valid']}
                        value={formData.email}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextValidator
                        key={'password'}
                        onChange={this.handleChange}
                        validators={['required']}
                        errorMessages={['this field is required']}
                        value={formData.password}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary"/>}
                        label="Remember me"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                    {errorMessage}
                </ValidatorForm>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password, action) => dispatch(actions.auth(email, password, action))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(SignIn));