import React, {Component} from "react";
import axios from "../../hoc/axios-orders";
import Aux from '../../hoc/Aux/Aux'
import Burger from '../../components/Burger/Burger'
import BuildControls from '../../components/Burger/BuildControls/BuildControls'
import Spinner from "../../components/UI/Spinner/Spinner"
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import Modal from '../../components/UI/Model/Model'
import OrderSummary from "../../components/Burger/OrderSummary/OrderSummary";
import {connect} from "react-redux";
import * as burgerBuilderActions from "../../store/actions/index";


class BurgerBuilder extends Component {

    state = {
        purchasing: false,
        checkout: false
    }


    componentDidMount() {
        console.log("[componentDidMount]")
        this.props.onFetchIngredients();
    }

    purchaseHandler = () => {
        this.setState({purchasing: true})
    }

    purchaseCancelHandler = () => {
        this.setState({purchasing: false})
    }

    purchaseContinueHandler = () => {
        this.props.history.push({
            pathname: "/checkout"
        });
    }

    render() {
        let burger = this.props.fetchIngredientsError ? <p>Ingredients can't be loaded </p> : <Spinner/>
        let orderSummary = null;

        if (this.props.ings !== null) {
            console.log("[purchasable]" +this.props.purchasable)
            /**
             * Redux state 'ings' used here
             */
            const disabledInfo = {
                ...this.props.ings
            };

            for (let key in disabledInfo) {
                disabledInfo[key] = disabledInfo[key] <= 0;
            }
            orderSummary = <OrderSummary
                totalPrice={this.props.price}
                ingredients={this.props.ings}
                purchaseCanceled={this.purchaseCancelHandler}
                purchaseContinued={this.purchaseContinueHandler}/>

            burger = (
                <Aux>
                    <Burger ingredients={this.props.ings}/>
                    <BuildControls
                        currentPrice={this.props.price}
                        disabled={disabledInfo}
                        /*                      REDUX STATE CHANGE
                        * This is sneaky! Be are passing method references here AND those methods are defined
                        * to expect arguments. As you can see there are NO arguments here. This hides the fact
                        * that this is code logic waiting to happen. Inside BuildControls these methods get fired
                        * with the correct arguments.
                        * */
                        ingredientAdded={this.props.onIngredientAdded}
                        ingredientDeleted={this.props.onIngredientRemoved}
                        /**
                        * done
                        */
                        purchasable={this.props.purchasable}
                        ordered={this.purchaseHandler}
                    />
                </Aux>);
        }
        /**
         * Render
         */
        return (
            <Aux>
                <Modal show={this.state.purchasing} modalClosed={this.purchaseCancelHandler}>
                    {orderSummary}
                </Modal>
                {burger}
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        ings: state.burger.ingredients,
        price: state.burger.totalPrice,
        purchasable: state.burger.purchasable,
        fetchIngredientsError: state.burger.fetchError
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onIngredientAdded: (ingName) => dispatch( burgerBuilderActions.addIngredient(ingName) ),
        onIngredientRemoved: (ingName) => dispatch( burgerBuilderActions.removeIngredient(ingName) ),
        onFetchIngredients: () => dispatch(burgerBuilderActions.fetchIngredients())
    }
};
/**
 * Here we are wrapping the entire BurgerBuilder component in our own hoc, withErrorHandler.js.
 *
 * This code will get executed BEFORE anything is rendered to the screen. But REMEMBER!!!
 *
 * All JSX first gets written into a copy of the DOM, then it gets compared to the actual DOM
 * and if something is different, it updates the new DOM and then renders it.
 *
 * All the JSX turns into a document of HTML mixed with JavaScript and once that document is
 * complete we have a DOM. So the important part to remember is that all the code in our Burger-
 * Builder runs, it generates a complete DOM and then renders it.
 */
export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(BurgerBuilder, axios));