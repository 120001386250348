import React from 'react'

import Logo from '../../Logo/Logo'
import NavigationItems from '../NavigationItems/NavigationItems'
import classes from './SideDrawer.module.css'
import Backdrop from '../../UI/Backdrop/Backdrop'
import Aux from '../../../hoc/Aux/Aux'


const sideDrawer = (props) => {

    let attachedClasses = [classes.SideDrawer, classes.Close];

    if(props.open){
        attachedClasses = [classes.SideDrawer,classes.Open]
    }

    return (
        <Aux>
            <Backdrop  show={props.open} clicked={props.closed}/>
            {/* className has to be a string so we use join to get a space separated string*/}
            <div className={attachedClasses.join(' ')}>
                {/*
                    Logo is wrapped in div so that we can control its size when switching
                    between mobile and non-mobile
                 */}
                <div className={classes.Logo}>
                    <Logo/>
                </div>

                <nav>
                    <NavigationItems/>
                </nav>
            </div>
        </Aux>
    );
}

export default sideDrawer