import React, {Component} from 'react'

import Model from "../../components/UI/Model/Model";
import Aux from '../Aux/Aux'


/**
 * This withErrorHandler is a Vanilla JavaScript ES6 function!!!
 * ES6 introduced the 'arrow' function declaration as a shorter signature.
 *
 * The first 'return' IS React and it returns JSX. This is why we import React
 * which enables us to get props and brings React "in-scope" which gives us
 * all the magic of using React.
 *
 * @param MyDreamedUpNameComponent
 *
 */
const withErrorHandler = (MyDreamedUpNameComponent, axios) => {
    return class extends Component {

        state = {
            error: false
        }

        reqInterceptor=null;
        respInterceptor=null;

        constructor(props) {
            super(props);

            this.reqInterceptor = axios.interceptors.request.use(req => {
                this.setState({error: null});
                return req;
            })

            this.respInterceptor = axios.interceptors.response.use(response => {
                return response;
            },error => {
                this.setState({error: error});
            })
            window.addEventListener('beforeunload', this.componentWillUnmount);

        }

        componentDidMount() {
            console.log("[componentDidMount]");
        }

        componentWillUnmount() {
            console.log("[withErrorHandler] componentWillUnMount")
            axios.interceptors.request.eject(this.reqInterceptor);
            axios.interceptors.response.eject(this.respInterceptor);
        }

        errorConfirmedHandler = () => {
            this.setState({error: null})
        }

        render() {
            return (
                /**
                 * Here <Aux/> enables us to return two adjacent objects. They are
                 * <Model/> and <MyDreamedUpNameComponent/>
                 */
                <Aux>
                    <Model show={this.state.error}
                           modalClosed={this.errorConfirmedHandler}>
                        {this.state.error?this.state.error.message:null}
                    </Model>
                    {/* Pass props to wrapped component*/}
                    <MyDreamedUpNameComponent {...this.props}/>
                </Aux>
            )
        }
    }
}

export default withErrorHandler