import React, {useState} from 'react';
import { useDispatch } from 'react-redux/lib/hooks/useDispatch';
import * as actions from "../store/actions";

const useSignUpForm = () => {
    const [inputs, setInputs] = useState({
        email: "",
        firstName: "",
        lastName: "",
        password: "",
    });
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
            dispatch(actions.auth(inputs.email, inputs.password, "SingUp"))
            console.log(inputs);

        }
    }
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }
    return {
        handleSubmit,
        handleInputChange,
        inputs
    };
}

export default useSignUpForm;