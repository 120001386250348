import * as actionTypes from './actionTypes'
import axios from "../../hoc/axios-orders";
import {FETCH_INGREDIENTS_ERROR, STORE_INGREDIENTS} from "./actionTypes";

/**
 *
 * ACTIONS live so that you have the possibility to run asynchronous code. The whole
 * purpose of this file is to provide a middle ground where you can use something like
 * redux-thunk to execute async code BEFORE you alter the Store.
 *
 * dispatch -> action -> store
 *
 */


/**
 *
 * SYNCHRONOUS FUNCTIONS ONLY
 *
 */
export const addIngredient = (ingName) => {
    return {
        type: actionTypes.ADD_INGREDIENT,
        ingredientName: ingName
    };
}

export const removeIngredient = (ingName) => {
    return {
        type: actionTypes.REMOVE_INGREDIENT,
        ingredientName: ingName
    };
}

export const storeIngredients = (ingredients) => {
    return {
        type: STORE_INGREDIENTS,
        ingredients: ingredients.ingredients
    }
}

export const errorFetchingIngredients = () => {
    return {
        type: FETCH_INGREDIENTS_ERROR
    }
}
/**
 *
 * ASYNCHRONOUS FUNCTIONS ONLY
 *
 * npm install --save redux-thunk
 */
export const fetchIngredients = (ingredients) => {
    return (dispatch) => {
        axios.get('/ingredients.json')
            .then(response => {
                dispatch(
                    storeIngredients({ingredients: response.data})
                )
            })
            .catch((error) => {
                dispatch(
                    errorFetchingIngredients()
                )
            })

    }
}