import React, {Component} from 'react';
import Order from '../../components/Order/Order'
import axios from "../../hoc/axios-orders";
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import * as orderActions from "../../store/actions";
import {connect} from "react-redux";
import Spinner from "../../components/UI/Spinner/Spinner";


class Orders extends Component {

    componentDidMount() {
        console.log("[componentDidMount]")
        this.props.onFetchOrder();
    }

    render() {
        let orders = this.props.errorLoading ? <p>Orders can't be loaded </p> : <Spinner/>

        if(this.props.orders !== null)
        {
            orders = this.props.orders.map(order => (
                <Order key={order.id}
                       ingredients={order.burger.ingredients}
                       price={+order.burger.price}/>
            ))
        }

        return (
            <div>
                {orders}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        orders: state.fetchedOrders.orders,
        errorLoading: state.fetchedOrders.errorLoading
    }
}
/**
 * This is kind of ugly because we import everything in actionCreators which causes us
 * to loose nice linting.
 * @param dispatch
 * @returns
 */
const mapDispatchToProps = dispatch => {
    return {
        onFetchOrder: () => dispatch(orderActions.fetchOrders())
    }
};
export default connect(mapStateToProps,mapDispatchToProps)(withErrorHandler(Orders, axios));