import React from 'react'
import classes from './Button.module.css'


/**
 * This is a re-usable button.
 *
 * className: this must receive a String. Here we want to pass in a btnType so that
 * during rendering the correct CSS class will be chosen.
 *
 * [classes.Button, classes[props.btnType] ].join(' ') creates an SPACE separated
 * String from the [classes.Button, classes[props.btnType] ] Array.
 * @param props
 * @returns {JSX.Element}
 */
const button = (props) => (
    <button
        disabled={props.disabled}
        className={[classes.Button, classes[props.btnType] ].join(' ')}
        onClick={props.clicked}>{props.children}
    </button>
);

export default button;