import React, {Component} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import SmokeCloud from "../../hoc/RootPage/SmokeCloud";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Spinner from '../../components/UI/Spinner/Spinner';
import connect from "react-redux/lib/connect/connect";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                PrattGorman
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = (theme) => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
});

class SignInSide extends Component {

    state = {
        signUp: false,
    }

    switchAuthModeHandler = () => {
        this.setState(prevState => {
            return {signUp: !prevState.signUp};
        })
    }

    render() {
        const {classes} = this.props;
        const inOutForm = this.state.signUp ? <SignUp/> : <SignIn/>;
        const suggestion = this.state.signUp ? "Already have an account? Sign in" : "Don't have an account? Sign Up";
        let sideGrid = <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
                {inOutForm}
                <Grid container >
                    <Grid item xs>
                        <Link href="#" variant="body2">
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to={"#"} onClick={this.switchAuthModeHandler}>
                            {suggestion}
                        </Link>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Copyright/>
                </Box>
            </div>
        </Grid>

        if(this.props.loading)
        {
            sideGrid = <Spinner/>
        }

        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline/>
                <Grid item xs={false} sm={4} md={7}>
                    <SmokeCloud/>
                </Grid>
                {sideGrid}
            </Grid>
        )
    };
}
const mapStateToProps = state => {
    return {
        loading: state.auth.loading
    }
}
export default connect(mapStateToProps)(withStyles(useStyles)(SignInSide));
