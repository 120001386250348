import {createStore, applyMiddleware, combineReducers} from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { createLogger } from "redux-logger";
/**
 * Reducers
 */
import burgerBuilder from "./reducer/burgerBuilder";
import order from "./reducer/order";
import orders from "./reducer/orders";
import authReducer from './reducer/auth'
/**
 * Tells React to merge these reducers together into one Store,one State, one Reducer.
 *
 * We will be able to reference 'builder' and 'orders' from within our app.
 *
 * @type {Reducer<unknown>}
 */
const rootReducer = combineReducers({
    burger: burgerBuilder,
    orderBurger: order,
    fetchedOrders: orders,
    auth: authReducer
});

const middleware = [thunk];

if (process.env.NODE_ENV !== "production") {
    middleware.push(createLogger());
}

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;