import React from 'react';
import classes from './Burger.module.css';
import BurgerIngredient from "./BurgerIngredient/BurgerIngredient";


const burger = (props) => {
    /**
     * This will convert the incoming STATE object to a Array of <BurgerIngredient/>
     * with a single element for every single instance of each ingredient, so if cheese
     * is 2 you will get two <BurgerIngredient/> of type cheese and so on.
     */
    let transformedIngredients = Object.keys(props.ingredients)
        .map(igKey => {
            return [...Array(props.ingredients[igKey])]
                .map((key, index) => {
                    return <BurgerIngredient key={igKey + index} type={igKey}></BurgerIngredient>;
                })
        })
        /**
         * Flatten the results
         * reduce (previous,current => {}, [INITIAL] .. any empty array here.)
         *
         *  This will loop through all the values and ADD them to the initial value.
         *  The INITIAL value is ALWAYS added
         */
        .reduce((previousValue, currentValue) => {
            return previousValue.concat(currentValue);
        }, [])
    /**
     * This will force salad to always be on the top of the other ingredients
     */
    transformedIngredients.sort((x, y) => {
        return x.props.type === 'salad' ? -1 : y.props.type === 'salad' ? 1 : 0
    })


    if (transformedIngredients.length === 0) {
        transformedIngredients = <p>Please start adding ingredients!</p>
    }

    return (
        <div className={classes.Burger}>
            <BurgerIngredient type={"bread-top"}/>
            {transformedIngredients}
            <BurgerIngredient type={"bread-bottom"}/>
        </div>
    );
}

export default burger;