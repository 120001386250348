import React, {Component} from 'react';
import {Route, Redirect} from "react-router-dom";
import ContactData from "./ContactData/ContactData";
import CheckoutSummary from '../../components/Order/CheckoutSummary/CheckoutSummary'
import {connect} from "react-redux";
import * as actions from "../../store/actions/index";


class Checkout extends Component {

    componentDidMount() {
        console.log("[before render]")
        this.props.onInitOrder()
    }

    componentWillUnmount(){
        this.props.onInitOrder()
    }

    checkoutCancelledHandler = () => {
        this.props.history.goBack();
    }

    checkoutContinueHandler = () => {
        this.props.history.replace('/checkout/contact-data');
    }

    render() {
        let summary = <Redirect to={"/"}/>

        if (this.props.ings) {

            const purchasedRedirect =this.props.purchased ?  <Redirect to={"/"}/> : null

            summary = (
                <div>

                    {/*
                                !!! IMPORTANT !!!
                       If purchasedRedirect is not null the CheckoutSummary will render.

                       Otherwise we redirect and never process the CheckoutSummary Component.
                    */}

                    {purchasedRedirect}
                    <CheckoutSummary ingredients={this.props.ings}
                                     checkoutCancelled={this.checkoutCancelledHandler}
                                     checkoutContinued={this.checkoutContinueHandler}/>
                    <Route path={this.props.match.path + '/contact-data'} component={ContactData}/>
                </div>
            )

        }

        return summary;
    }
}

const mapStateToProps = state => {
    return {
        ings: state.burger.ingredients,
        purchased: state.orderBurger.purchased
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitOrder: () => dispatch(actions.orderInit())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Checkout);