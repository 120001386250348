import React, {Component} from 'react';
import Aux from '../Aux/Aux'
import classes from './Layout.module.css'
import Toolbar from '../../components/Navigation/Toolbar/Toolbar'
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";


class Layout extends Component {

    state = {
        showSideDrawer: false
    }

    /* By using the arrow function we are making sure that the
        * THIS keyword always points to this instance Object */
    sideDrawerClosedHandler = () => {
        this.setState({showSideDrawer: false})
    }
    /*
    * This is the most reliable way to change state
    */
    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return {showSideDrawer: !prevState.showSideDrawer};
        })
    }

    render() {
        return (
            <Aux>
                <Toolbar clicked={this.sideDrawerToggleHandler}/>
                <SideDrawer open={this.state.showSideDrawer}
                            closed={this.sideDrawerClosedHandler}/>
                <main className={classes.Content}>
                    {this.props.children}
                </main>
            </Aux>
        )
    }

}

export default Layout;