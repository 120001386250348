import React from 'react'
import classes from './Toolbar.module.css'
import Logo from '../../Logo/Logo'
import NavigationItems from '../NavigationItems/NavigationItems'
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle'


const toolbar = (props) => (
    <header className={classes.Toolbar}>
        <DrawerToggle showSideBar={props.clicked}/>
        {/*
                Logo is wrapped in div so that we can control its size when switching
                between mobile and non-mobile
         */}
        <div className={classes.Logo}>
            <Logo/>
        </div>
        <nav className={classes.DesktopOnly}>
            <NavigationItems/>
        </nav>
    </header>
)


export default toolbar;