/**
 * This component has the single purpose of meeting the requirement of React.js that
 * every component return only one ROOT level object. The props.children return statement
 * returns everything that is defined in a Component that is wrapped by this 'aux'
 * component. You could add/remove things before your return the children if you needed
 * too, here we don't.
 * @param props
 */

const aux = props => {return props.children};

export default aux;