import {ORDER_BURGER_SUCCESS, ORDER_BURGER_FAILED, ORDER_BURGER_START, ORDER_INIT} from "../actions/actionTypes";

const initialState = {
    order: [],
    loading: false,
    purchased: false

}

const order = (state = initialState, action) => {
    switch (action.type) {
        case ORDER_BURGER_SUCCESS: {
            /**
             * create a new Order with the given ID
             * @type {{id}}
             */
            const newOrder = {
                ...action.orderData,
                id: action.orderId
            }

            return {
                ...state,
                loading: false,
                purchased: true,
                orders: state.order.concat(newOrder)
            }
        }
        case ORDER_BURGER_FAILED: {
            return {
                ...state,
                loading: false
            }
        }
        case ORDER_BURGER_START: {
            return {
                ...state,
                loading: true
            }
        }
        case ORDER_INIT: {
            return {
                ...state,
                purchased: false
            }
        }
        default:
            return state;
    }
}
export default order