import React, {Component} from 'react';
import Button from '../../../components/UI/Button/Button'
import Spinner from '../../../components/UI/Spinner/Spinner'
import Input from '../../../components/UI/Input/Input'
import classes from './ContactData.css'
import * as orderActions from "../../../store/actions/index";
import {connect} from "react-redux";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../../hoc/axios-orders";

class ContactData extends Component {

    state = {
        orderForm: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Your Name'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            street: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Street'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            zipCOde: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Zip Code'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 5,
                    maxLength: 15
                },
                valid: false,
                touched: false
            },
            country: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Country'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Email'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            deliveryMethod: {
                elementType: 'select',
                elementConfig: {
                    options: [{value: 'fastest', displayValue: 'Fastest'},
                        {value: 'cheapest', displayValue: 'Cheapest'}]
                },
                value: 'fastest',
                validation: {},
                valid: true
            },
        },
        formIsValid: false
    }


    /**
     * This is NASTY!
     *
     * When you are inside a <form/> with a <button> WHENEVER you click the button the
     * default behavior will be to call 'submit', which will RE-RENDER the <form/>.
     *
     * In normal HTML you should be able to prevent this by specifying a 'type'
     * @Link (https://stackoverflow.com/questions/2440043/button-in-the-form-cause-it-be-submitted-automatically)
     *
     * Here in React you seem to have to use event.preventDefault() to stop it from calling submit
     * and thereby RE-RENDER-ing the <form/>
     *
     * Here bullshit shows we can make of the name of the passed in arguments. Apparently, when you
     * handle 'clicked' the method handler gets the <button/> Object and all its properties. This
     * gives us access to preventDefault().
     *
     * @param event
     */
    orderHandler = (bullshit,ingredients,price) => {
        bullshit.preventDefault();

        const formData = {};
        for (let formElementIdentifier in this.state.orderForm) {
            // email/country/etc
            formData[formElementIdentifier] = this.state.orderForm[formElementIdentifier].value;
        }

        /**
         * Here is wy redux is good
         *
         * ings and price are now provided by a global state rather that passing
         * parameters in a URL string.
         */
        const order = {
            ingredients: this.props.ings,
            price: this.props.price,
            orderData: formData
        }
        /**
         * Axios and Redux getting called
         */
        this.props.onOrderBurger(order);
    }

    /**
     *
     * @param value
     * @param rules
     * @returns {boolean}
     */
    checkValidation(value, rules) {
        let isValid = true;
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        if(rules.minLength)
        {
            isValid = value.length >= rules.minLength && isValid;
        }
        if(rules.maxLength)
        {
            isValid = value.length <= rules.maxLength && isValid;
        }
        return isValid;
    }

    /**
     * The 'event' gets automatically passed in by React if this method is attached to an event
     * listener.
     *
     * This is a great example of two-way binding. As the user enters data it displays on the
     * page AND back in this Component we receive it.
     * @param event
     */
    inputChangedHandler = (event, inputIdentifier) => {
        /**
         * DEEP CLONE here. Since our JSON object has nested Objects we have to create
         * a DEEP clone so that the nested objects are COPIED. Otherwise only the parent
         * level items would be copied and their children (name,street,zip) would be
         * pointers - pointing to their REAL (our state) values.
         *
         */
        const updatedOrderForm = {
            ...this.state.orderForm
        };
        // This accounts for the DEEP clone.
        const updatedFormElement = {...updatedOrderForm[inputIdentifier]};

        updatedFormElement.value = event.target.value;
        updatedFormElement.touched = true;

        updatedFormElement.valid = this.checkValidation(
            updatedFormElement.value,
            updatedFormElement.validation
        );

        updatedOrderForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for(let inputIdentifier in updatedOrderForm){
            formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid;
        }

        this.setState({orderForm: updatedOrderForm, formIsValid: formIsValid});
    }


    render() {
        const formElementsArray = [];
        for (let key in this.state.orderForm) {
            //key = name,street,zipcode etc
            formElementsArray.push({
                id: key,
                config: this.state.orderForm[key]
            })
        }

        let form = (<form onSubmit={this.orderHandler}>
            {formElementsArray.map(formElement => (
                <Input key={formElement.id}
                       elementType={formElement.config.elementType}
                       elementConfig={formElement.config.elementConfig}
                       value={formElement.value}
                       /**
                       * Pay Attention! We pass an anonymous function here enabling us to pass
                       * arguments. Again, React automatically adds 'event'
                       */
                       changed={(event) => this.inputChangedHandler(event, formElement.id)}
                       /**
                       * Notice the NOT (!)
                       */
                       invalid={!formElement.config.valid}
                       shouldValidate={formElement.config.validation}
                       touched={formElement.config.touched}
                />
            ))}
            <Button disabled={!this.state.formIsValid} btnType={"Success"}>ORDER</Button>
        </form>);

        if (this.props.loading) {
            form = <Spinner/>
        }


        return (
            <div className={classes.ContactData}>
                <h4>Enter your Contact Data</h4>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.orderBurger.loading,
        ings: state.burger.ingredients,
        price: state.burger.totalPrice
    }
}
/**
 * This is kind of ugly because we import everything in actionCreators which causes us
 * to loose nice linting.
 * @param dispatch
 * @returns
 */
const mapDispatchToProps = dispatch => {
    return {
        onOrderBurger: (burger) => dispatch(orderActions.orderBurger(burger))
    }
};
export default connect(mapStateToProps,mapDispatchToProps)(withErrorHandler(ContactData,axios));