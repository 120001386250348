import React from 'react'
import Aux from '../../../hoc/Aux/Aux'
import Button from '../../UI/Button/Button'
import {Component} from "react/cjs/react.production.min";

class OrderSummary extends Component {
    /* This does not have to be a class, it could be functional */
    render() {
        /**
         * We have to transform props.ingredients because it is given to us in Object form.
         */
        const
            ingredientSummary = Object.keys(this.props.ingredients)
                .map(igKey => {
                    return <li key={igKey}>
                        <span style={{textTransform: 'capitalize'}}>{igKey}</span>: {this.props.ingredients[igKey]}
                    </li>;
                })

        return (
            <Aux>
                <h3>Your Order</h3>
                <p>A delicious burger with the follow ingredients:</p>
                <ul>
                    {ingredientSummary}
                </ul>
                <p><strong>Total Price: {this.props.totalPrice.toFixed(2)}</strong></p>
                <p>Continue to Checkout?</p>
                <Button btnType={"Danger"} clicked={this.props.purchaseCanceled}>CANCEL</Button>
                <Button btnType={"Success"} clicked={this.props.purchaseContinued}>CONTINUE</Button>
            </Aux>
        )
    };
}
export default OrderSummary;