import {STORE_FETCHED_ORDERS} from "../actions/actionTypes";

const initialState = {
    orders: null,
    errorLoading: false
}

const orders = (state = initialState, action)=> {
    switch (action.type){
        case STORE_FETCHED_ORDERS:
            return {
                ...state,
                orders: action.orders
            }

        default:
            return state;
    }
}
export default orders