import axios from "../../hoc/axios-orders";
import {FETCH_INGREDIENTS_ERROR, STORE_FETCHED_ORDERS} from "./actionTypes";



export const errorFetchingOrders = () => {
    return {
        type: FETCH_INGREDIENTS_ERROR
    }
}

export const storeOrders = (orders) => {
    return {
        type: STORE_FETCHED_ORDERS,
        orders: orders.orders
    }
}
/**
 *
 * ASYNCHRONOUS FUNCTIONS ONLY
 *
 * npm install --save redux-thunk
 */
export const fetchOrders = () => {
    return (dispatch) => {
        axios.get('/orders.json')
            .then(response => {
                const fetchedOrders = [];
                // returns a json object
                for (let key in response.data) {
                    fetchedOrders.push({
                        ...response.data[key],
                        id: key
                    });
                }
                dispatch(
                    storeOrders({orders: fetchedOrders})
                )
            })
            .catch((error) => {
                dispatch(
                    errorFetchingOrders()
                )
            })

    }
}