import React, { useRef, useState,useMemo } from 'react'
import * as THREE from "three";
import { a } from "react-spring/three"
import {useFrame} from "react-three-fiber";
import smoke from '../../../assets/images/smoke.png'

const HoboLogo = (props) => {
    let cubeSineDriver=0;
    const mesh = useRef();

    const [active, setActive] = useState(false)

    const smokeTexture = useMemo(() => new THREE.TextureLoader()
            .load(smoke),
        []);

    useFrame((state, delta) => {
        cubeSineDriver += .01;
        mesh.current.rotation.z += (delta * 0.2)
    });

    return (
        <a.mesh
            {...props}
            ref={mesh}
            rotation={[0,0,Math.random() * 360]}
            position={[Math.random() * 200 - 100, Math.random() * 200 - 100, Math.random() * 1000 - 100]}
        >
            <planeBufferGeometry attach="geometry"
                                 args={[300, 300]}

            />
            <a.meshLambertMaterial
                attach="material"
                color={0x6ecbea}
                opacity={1}
                map={smokeTexture}
                transparent={true}
            />
        </a.mesh>
    )

}
export default HoboLogo;