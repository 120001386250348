import React,{ Component } from 'react'
import {Canvas} from "react-three-fiber";
import HoboLogo from '../../components/UI/HoboLogo/HoboLogo'
import {PerspectiveCamera} from '@react-three/drei';


class SmokeCloud extends Component {

    render () {
        let smoke = [];
        for(let p=0; p<30; p++){
            smoke.push(<HoboLogo key={p}/>)
        }
        return (
            <Canvas
                    style={{ height: "100%", width: "100%", background: "#E9E4DC" }} >
                <PerspectiveCamera makeDefault args={[75, 1, 1, 10000]} position={[0, 0, 1300]}/>
                <ambientLight args={[0x404040]}/>
                <directionalLight args={[0xFFFFFF, 1]} position={[-1, 0, 1]}/>

                {smoke}
            </Canvas>
        );
    }
}
export default SmokeCloud;