import React from 'react'
import classes from './BuildControls.module.css'
import BuildControl from './BuildControl/BuildControl'

const controls = [
    {label: 'Salad', type: 'salad'},
    {label: 'Bacon', type: 'bacon'},
    {label: 'Cheese', type: 'cheese'},
    {label: 'Meat', type: 'meat'},
];

const buildControls = (props) => (
    <div className={classes.BuildControls}>
        <p>Current Price: <strong>{props.currentPrice.toFixed(2)}</strong></p>
        {controls.map(ctrl => (
            // the added variable is declared here
            <BuildControl
                key={ctrl.label}
                label={ctrl.type}
                added={() => props.ingredientAdded(ctrl.type)}
                deleted={() => props.ingredientDeleted(ctrl.type)}
                disabled={props.disabled[ctrl.type]}
                />
        ))
        }
    <button
        onClick={props.ordered}
        className={classes.OrderButton}
        disabled = {!props.purchasable}>ORDER NOW</button>
    </div>
)

export default buildControls

