import React from 'react';
import {NavLink} from 'react-router-dom'
/**
 * NavLink allows you to style the links.
 *
 * Here we don't even have to define className for the NavLink. Its convention is to
 * assume your CSS is
 */

import classes from './NavigationItem.module.css'


const navigationItem = (props) => {

    return (
        <li className={classes.NavigationItem}>
            <NavLink
                /**
                * We have to use activeClassName because we use css-modules which appends
                * a unique identifier on the end of the CSS classnames. This unique portion
                * of the name causes the NavLink "convention" to fail picking up our CSS.
                */
                activeClassName={classes.active}
                /**
                * Here the props.link determines whether or not the link is active.
                * <NavLink/> is a router and it interprets the link name by generally
                * matching the props.link but as a PREFIX to anything in the link. So if this
                * props.link is set to "/" it will match, but it will also match "/anything"
                * because this too begins with a slash. So we have to put "exact" as a property.
                */
                to={props.link}
                exact = {props.exact}>
                {props.children}</NavLink>
        </li>
    );
};

export default navigationItem;