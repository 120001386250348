import {ADD_INGREDIENT, REMOVE_INGREDIENT, STORE_INGREDIENTS,FETCH_INGREDIENTS_ERROR} from "../actions/actionTypes";

const INGREDIENT_PRICES = {
    salad: 0.5,
    cheese: 0.4,
    meat: 1.3,
    bacon: 0.7
};

const initialState = {
    ingredients: null,
    totalPrice: 4,
    purchasable: false,
    fetchError: false
};

const burgerBuilder = (state = initialState, action)=>{

    switch (action.type){
        case ADD_INGREDIENT:
            /**
             * Here we do a shallow copy with the spread on state. Because of that
             * we have to do the same for 'intredients' because the shallow copy
             * doesn't get that. Then we use the spread on it and then an ES6 syntax
             * to DYNAMICALLY override a property in a given JS object --> []
             * we use it to target the item to replace.
             */
            return {
                ...state,
                ingredients: {
                    ...state.ingredients,
                    [action.ingredientName]: state.ingredients[action.ingredientName] + 1
                },
                totalPrice: state.totalPrice + INGREDIENT_PRICES[action.ingredientName],
                purchasable: (state.totalPrice + + INGREDIENT_PRICES[action.ingredientName] > 4)
            }
        case REMOVE_INGREDIENT:
            return {
                ...state,
                ingredients: {
                    ...state.ingredients,
                    [action.ingredientName]: state.ingredients[action.ingredientName] - 1
                },
                totalPrice: state.totalPrice - INGREDIENT_PRICES[action.ingredientName],
                purchasable: (state.totalPrice - INGREDIENT_PRICES[action.ingredientName] > 4)
            }
        case STORE_INGREDIENTS:
            return {
                ...state,
                ingredients: action.ingredients,
                fetchError: false,
                totalPrice: 4
            }
        case FETCH_INGREDIENTS_ERROR: {
            return{
                ...state,
                fetchError: true
            }

        }
        default:
            return state;
    }
}

export default burgerBuilder