export const ADD_INGREDIENT = 'ADD_INGREDIENT';
export const REMOVE_INGREDIENT = 'REMOVE_INGREDIENTS';
export const STORE_INGREDIENTS = 'STORE_INGREDIENTS';
export const FETCH_INGREDIENTS_ERROR = 'FETCH_INGREDIENTS_ERROR';
export const STORE_FETCHED_ORDERS = 'STORE_FETCHED_ORDERS';

export const ORDER_BURGER_SUCCESS = 'ORDER_BURGER_SUCCESS';
export const ORDER_BURGER_FAILED = 'ORDER_BURGER_SUCCESS';
export const ORDER_BURGER_START = 'ORDER_BURGER_START';

export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'

export const ORDER_INIT = 'ORDER_INIT';