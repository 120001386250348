import axios from "../../hoc/axios-orders";
import {ORDER_BURGER_SUCCESS,ORDER_BURGER_FAILED,ORDER_BURGER_START, ORDER_INIT} from "./actionTypes";





export const orderBurgerFailed = (error) => {
    return {
        type: ORDER_BURGER_FAILED,
        error: error
    }
}

export const orderBurgerSuccess = (id, orderData) => {
    return {
        type: ORDER_BURGER_SUCCESS,
        orderId: id,
        orderDate: orderData
    }
}

export const orderBurgerStart = () => {
    return {
        type: ORDER_BURGER_START
    }
}

export const orderInit = () => {
    return {
        type: ORDER_INIT
    }
}

export const orderBurger = (burger) => {
    return (dispatch) => {
        dispatch(orderBurgerStart());

        axios.post('/orders.json',
            {
                burger
            })
            .then((response) => {
                console.log("Ordered");
                dispatch(orderBurgerSuccess(response.data.name, burger));
            }).catch(function (error) {
            orderBurgerFailed(error);
                console.log("FAILED to Order");
                dispatch(orderBurgerFailed(error));
        });

    }
}