import React from 'react';
import NavigationItem from './NavigationItem/NavigationItem'
import classes from './NavigationItems.module.css'


const navigationItems = (props) => (
    <ul className={classes.NavigationItems}>
        {/*BEWARE:  'exact' here has nothing to do with react-router-dom it is our own property*/}
            <NavigationItem link = {"/"} exact >Burger Builder</NavigationItem>
            <NavigationItem link = {"/orders"} >Orders</NavigationItem>
    </ul>
);

export default navigationItems;